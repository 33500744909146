<template lang="pug">
.cdn-image
  picture
    source(:srcset="webpSrcSet", :type="'image/webp'")
    source(:srcset="jpegSrcSet", :type="'image/jpeg'")

    img(:src="src", :alt="alt", :style="style", loading="lazy")
</template>
<script setup lang="ts">
const props = defineProps({
  src: {
    type: String,
    required: true
  },
  alt: {
    type: String
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  quality: {
    type: Number,
    default: 75
  },
  fit: {
    type: String as PropType<'cover' | 'contain'>,
    required: false,
    default: () => 'cover',
    validator: (value: string) => ['cover', 'contain'].indexOf(value) !== -1
  }
})

const src = cdnImageSrc(props.src, props.width, props.height, props.quality, 'jpeg', props.fit)
const webpSrcSet = cdnImageSrcSet(props.src, props.width, props.height, props.quality, 'webp', props.fit)
const jpegSrcSet = cdnImageSrcSet(props.src, props.width, props.height, props.quality, 'jpeg', props.fit)
const style = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
    objectFit: props.fit
  }
})
</script>
<style lang="sass" scoped>
.cdn-image
  display: inline-flex
  align-items: center
  justify-content: center

  img
    object-fit: contain
</style>