type ImageFormat = 'jpeg' | 'png' | 'webp'

export function cdnImageSrc(filename: string, width: number, height: number, quality: number = 75, format: ImageFormat = 'jpeg', fit: 'cover' | 'contain' = 'cover') {
  const filePath = filename.startsWith('/') ? filename : `/${filename}`
  const fitIn = fit == 'contain' ? 'fit-in/' : ''
  const fill = fit == 'contain' ? 'filters:fill(fff)/' : ''

  return `https://images.boxmagenta.com.br/${fitIn}${width}x${height}/filters:quality(${quality})/filters:background_color(fff)/${fill}filters:format(${format})/development${filePath}`
}

export function cdnImageSrcSet(filename: string, width: number, height: number, quality: number = 75, format: ImageFormat = 'jpeg', fit: 'cover' | 'contain' = 'cover') {
  const imageSrc1x = cdnImageSrc(filename, width, height, quality, format, fit)
  const imageSrc2x = cdnImageSrc(filename, width * 2, height * 2, quality, format, fit)
  const imageSrc3x = cdnImageSrc(filename, width * 3, height * 3, quality, format, fit)

  return `${imageSrc1x} 1x, ${imageSrc2x} 2x, ${imageSrc3x} 3x`
}